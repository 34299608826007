<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg12 pa-lg-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <!----------------------filters---------------------------->
          
          <v-layout wrap>
       <v-flex xs12 sm9 md10 pb-2 class="nsbold text-left"><span style="font-size:20px;">Study Permission Report</span></v-flex>
           <v-flex xs12 sm2 md2 pb-2>
            <download-excel :data="myData" :fields="json_fields">
                    <v-btn
                      width="155px"
                      dark
                      style="text-transform: none"
                      color="#766BC0"
                     class="hidden-xs-only"
                      >Download Excel</v-btn
                    >
                    <v-btn
                      width="155px"
                      dark
                      block
                      style="text-transform: none"
                      color="#766BC0"
                     class="hidden-sm-and-up"
                      >Download Excel</v-btn
                    >
                  </download-excel>
          </v-flex>
            <v-flex xs12 sm6 lg3 pb-2 pb-lg-0>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 lg3 pl-sm-4 pb-2 pb-lg-0>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    hide-details
                    outlined
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 lg3 pl-lg-4 pb-2 pb-lg-0>
              <v-text-field
                v-model="keyword"
                dense
                hide-details
                label="Search : Ad.no/Name"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 lg3 pl-sm-4 pb-2 pb-lg-0>
              <!-- <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select> -->
              <v-select
                :items="issueStatus2"
                v-model="cvalue"
                item-text="text"
                item-value="value"
                label="Status"
                outlined
                hide-details
                dense
              ></v-select>
            </v-flex>
            <!--------------------------list------------------------>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="leave">
              <v-layout wrap py-md-2 class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="leave.length > 0">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admission No</th>
                          <th class="text-left" style="width:17%">Name</th>
                          <th style="width:6%" class="text-left">Class</th>
                          <th style="width:17%" class="text-left">From(Time)</th>
                          <th style="width:17%" class="text-left">To (Time)</th>
                          <th class="text-left">Purpose</th>
                          <th class="text-left">Status</th>
                          <!-- <th  class="text-left">View</th> -->
                          <!-- <th class="text-left">Approve</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in leave" :key="i" py-4>
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ item.studentid.admissionNo }}
                          </td>
                          <td><span class="nsregular"><router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item.studentid._id"
                            >
                            {{ item.studentid.name }}</router-link></span>
                            </td>
                          <td>
                            <span v-if="item.studentid">
                              {{ item.studentid.Class }} :
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.fromTime">
                              {{ formatDate(item.fromTime) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.toTime">
                              {{ formatDate(item.toTime) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.reason">
                              {{ item.reason }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.permissionStatus">
                              {{item.permissionStatus}}
                            </span>
                           
                            <span v-else>-</span>
                            <!-- <span v-if="item.leaveExtended==true">
                    <v-img  height="20px" width="15px" contain src="./../../assets/images/acc.webp"></v-img>

                            </span> -->
 
                             <v-icon small v-if="item.permissionStatus=='Rejected'" @click="(extenddialoge=true) , (Extent = item) " color="#766BC0">mdi-eye</v-icon>
                           
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <!-------------------dialogue box--------------------->
             
              <v-dialog width="500px" v-model="extenddialoge">
                <v-card width="500px">
                  <v-toolbar color="#766BC0" dense flat class="body-2">
                    <v-toolbar-title style="color: white"
                      >Rejection Info</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-layout wrap pa-2>
                    <v-flex xs12 >
                   <span class="nsbold">Status:</span><span class="nsregular">{{Extent.permissionStatus}}</span> 
                  </v-flex>
                  <v-flex xs12 v-if="Extent.rejectedBy">
                     <span class="nsbold">Rejected By: </span><span class="nsregular">{{Extent.rejectedBy.name}}</span>
                  </v-flex>
                  <v-flex xs12>
                     <span class="nsbold">Rejected Reason:</span><span class="nsregular"> {{Extent.rejectReason}}</span>
                  </v-flex>
                  </v-layout>
                  <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey"
                      text
                      class="body-2 font-weight-bold"
                      @click="extenddialoge = false"
                      >Close</v-btn
                    >
                    <!-- <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="approveData()"
                  >Approve</v-btn
                > -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!------------------------------------------>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      extenddialoge: false,
      menu: false,
      menu2: false,
      fromDate:null,
      toDate:null,
      // fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      // toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      keyword: null,
      msg: null,
      issueStatus: ["All", "Pending", "wApproved", "vcApproved"],
      Extent:[],
   // download excel
      myData: [],
      excel: {
        adno: "",
        name: "",
        class: "",
        from: "",
        to: "",
        purpose: "",
      },
      json_fields: {
        Ad_No: "adno",
        Name: "name",
        Class: "class",
        From_time: "from",
        To_time: "to",
        Purpose: "purpose",
      },
      //
      issueStatus2: [
        {
          value: "All",
          text: "All",
        },
        {
          value: "Pending",
          text: "Pending",
        },
        {
          value: "Approved",
          text: "Warden Approved",
        },
        // {
        //   value: "vcApproved",
        //   text: "Vice Principal Apporoved",
        // },
        {
          value: "Rejected",
          text: "Rejected",
        },
        //  {
        //     value: "wApproved",
        //     text: "Warden Approved"

        // }
      ],

      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      leave: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
     if(this.$route.query.stat == 'Pending') {
      this.cvalue='Pending'
    }
    if(this.$route.query.curdate) {
      this.fromDate=this.$route.query.curdate
      this.toDate=this.$route.query.curdate
    }
    this.getData();
      this.getExcel();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
      this.getExcel();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
      this.getExcel();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      this.getExcel();
    },
    cvalue() {
      if (this.page > 1) this.page = 1;
      this.getData();
      this.getExcel();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      let a;
      if (this.cvalue == "All") {
        a = "";
      } else {
        a = this.cvalue;
      }
      axios({
        method: "get",
        url: "/studypermission/getalllist",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          permissionStatus: a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.extenddialoge = false;
            this.msg = response.data.msg;
            this.leave = response.data.data;

            // for (let i = 0; i < this.leave.length; i++) {
            //   if (this.leave[i].issueStatus == "Active") {
            //     this.leave[i].issueStatus = "Issued";
            //   }
            // }

            this.Pagelength = Math.ceil(response.data.totallength / 20);
          } else {
            this.msg = response.data.msg;
            this.extenddialoge = false;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
     getExcel() {
      this.appLoading = true;
      let a;
      if (this.cvalue == "All") {
        a = "";
      } else {
        a = this.cvalue;
      }
      axios({
        method: "get",
        url: "/studypermission/getalllist",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          permissionStatus: a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.extenddialoge = false;
            this.msg = response.data.msg;
            this.leave = response.data.data;
      this.myData = [];

            //excel
             for (let i = 0; i < response.data.data.length; i++) {
              
              if (response.data.data[i].studentid.admissionNo) {
                this.excel.adno = response.data.data[i].studentid.admissionNo;
              }
              if (response.data.data[i].studentid.name) {
                this.excel.name = response.data.data[i].studentid.name;
              }
              if (response.data.data[i].studentid.Class) {
                this.excel.class = response.data.data[i].studentid.Class +"-"+response.data.data[i].studentid.division;
              }
              if (response.data.data[i].fromTime) {
                this.excel.from = this.formatDate(response.data.data[i].fromTime);
              }
              if (response.data.data[i].toTime) {
                this.excel.to = this.formatDate(response.data.data[i].toTime);
              }
              if (response.data.data[i].reason) {
                this.excel.purpose = response.data.data[i].reason;
              }

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }
          } else {
            this.msg = response.data.msg;
            this.extenddialoge = false;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
